import { Link } from "gatsby";
import React from "react";
// import Helmet from "react-helmet";

import Footer from "./Footer";
import Cookies from "./Parts/Cookies";

export default function Layout({children}){
    return(
        <div className="">
            <Cookies/>
            <div className="text-gray-700 w-full flex flex-col justify-center items-center space-y-4">
                {children}
            </div>
            <Footer/>
        </div>
    );
}
