import React from "react";
import Layout from "../components/Layout";
import SEO from '../components/seo';
import { Link } from "gatsby";
import checkGif from '../images/check_icon.gif';

const EnviadoPage = () => {

  return (
    <div>
        <SEO 
          title='Enviado com sucesso!'
          description=''
        />
        <div className="flex items-center justify-center w-full min-h-screen">
              <div className="flex flex-col w-4/5 items-center text-gray-800 space-y-2">
                  <img src={checkGif} alt="Enviado com sucesso!" />
                  <div className="text-2xl text-center">Enviado com sucesso!</div>
                  <div className="text-lg text-center">Em breve entraremos em contato.</div>
                  <Link to="/" className="underline text-lg">Voltar ao Inicio</Link>
              </div>
        </div>
    </div>
  )
}

export default EnviadoPage